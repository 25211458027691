<template>
  <div>
    <offline @detected-condition="handleConnectivityChange" />
    <div
      v-if="!isOnline"
      class="container"
    >
      <span>
        <i class="material-icons md-18">
          error_outline
        </i>
        {{ $t('CONNECTION.LOST') }}
      </span>
      <el-button
        type="danger"
        size="small"
        @click="reloadPage"
      >
        {{ $t("CONNECTION.RELOAD") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import Offline from 'v-offline'
export default {
  components: {
    Offline
  },
  computed: {
    isOnline () {
      return this.$store.getters['order/isOnline']
    }
  },
  methods: {
    handleConnectivityChange (status) {
      this.$store.dispatch('order/setIsOnline', status)
    },
    reloadPage () {
      this.$router.go()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $--color-offline-background;
  padding: 10px 20px 10px 20px;
  span {
    i {
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
    color: $--color-danger;
  }
  .material-icons {
    color: $--color-danger;
  }
}
</style>
