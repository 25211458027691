<template>
  <el-header class="desktop-header-bar">
    <div
      class="header-bar"
      data-tested-as="header"
      :class="{'scrolled': scrolled}"
    >
      <span class="header-left">
        <logo
          :white="true"
          :width="80"
          style="cursor:pointer"
          data-tested-as="logo"
          @click.native="goHome()"
        />
      </span>
      <span
        class="header-middle"
        data-tested-as="channels"
      >
        <el-button
          type="text"
          class="menu-link"
          data-tested-as="email-page"
          :class="{
            'is-selected': refChannels.EMAIL == params.channelId
          }"
          @click.native="updateChannel(refChannels.EMAIL)"
        >
          {{ $t('CAMPAIGN_TYPE_EMAIL') }}
        </el-button>
        <el-button
          data-tested-as="sms-page"
          type="text"
          class="menu-link"
          :class="{
            'is-selected': refChannels.MOBILE == params.channelId
          }"
          @click.native="updateChannel(refChannels.MOBILE)"
        >
          {{ $t('CAMPAIGN_TYPE_MOBILE') }}
        </el-button>
        <el-button
          data-tested-as="pn-page"
          type="text"
          class="menu-link"
          :class="{
            'is-selected': refChannels.PUSH_NOTIF == params.channelId
          }"
          @click.native="updateChannel(refChannels.PUSH_NOTIF)"
        >
          {{ $t('CAMPAIGN_TYPE_PUSH_NOTIF') }}
        </el-button>
      </span>
      <span class="header-right">
        <!-- SNAPCALL -->
        <SnapcallButton
          v-if="snapcallId"
          class="ml-1 mr-1"
          :is-active="showSnapcall"
          @update:is-active="launchSnapcall"
        />
        <!-- GATE SWITCH -->
        <NmpSwitch
          :clients="clients"
          :apps="apps"
          :current-switch-client="currentSwitchClient"
          :initial-client="initialClient"
          @update:currentSwitchClient="updateSwitchClient"
        />

        <el-dropdown
          trigger="hover"
          @command="handleCommand"
        >
          <el-button
            class="header-account header-button el-dropdown-link"
            :class="{ 'show-client-logo': clientLogo }"
            type="text"
          >
            <img
              v-if="clientLogo"
              class="client-logo mr-2"
              alt="Client logo"
              :src="clientLogo"
            >
            <img
              v-show="user.avatar_url"
              :src="user.avatar_url"
              style="border-radius: 50%;"
              alt="User avatar"
              class="user-logo"
              width="30"
              height="30"
            >
            <img
              v-show="!user.avatar_url"
              v-holder="'holder.js/30x30?text='+getInitials(user)"
              class="user-logo"
              alt="User avatar"
            >
          </el-button>

          <el-dropdown-menu
            slot="dropdown"
            class="menu-account"
          >
            <div class="menu-account--detail-container">
              <div class="menu-account--avatar-container">
                <img
                  v-show="user.avatar_url"
                  :src="user.avatar_url"
                  style="border-radius: 50%;"
                  alt="User avatar"
                  width="48"
                  height="48"
                >
                <img
                  v-show="!user.avatar_url"
                  v-holder="'holder.js/48x48?text='+getInitials(user)"
                  class="menu-account--avatar"
                  alt="User avatar"
                >
              </div>
              <div class="mb-1">
                {{ user.first_name + ' ' + user.last_name }}
              </div>
              <div class="mb-1 menu-account--email">
                {{ user.email }}
              </div>
            </div>
            <div>
              <el-dropdown-item
                command="profile"
              >
                <i class="material-icons">perm_identity</i>
                {{ $t('BUTTON_PROFILE') }}
              </el-dropdown-item>
              <el-dropdown-item
                command="logout"
              >
                <i class="material-icons">arrow_forward</i>
                {{ $t('BUTTON_SIGN_OUT') }}
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
    </div>
  </el-header>
</template>

<script>
import Logo from '@/components/UI/Logo'
import config from '@/config'
import { get } from 'lodash-es'
import { NmpSwitch, SnapcallButton } from '@team-crm-automation/nmp-components-vue2'

export default {
  name: 'UiHeader',
  components: { Logo, NmpSwitch, SnapcallButton },
  props: {
    scrolled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      refChannels: config.CHANNELS,
      currentSwitchClient: this.initialClient
    }
  },
  computed: {
    isMultiClient () {
      return this.$store.getters['auth/isMultiClients']
    },
    user () {
      return this.$store.getters['auth/user'] || {}
    },
    params () {
      return this.$store.getters['order/params']
    },
    initialClient () {
      return this.isMultiClient ? undefined : this.user.clients[0]
    },
    clients () {
      return this.$store.getters['clients/clients'] || []
    },
    apps () {
      return this.$store.getters['apps/apps'].apps || []
    },
    clientLogo () {
      if (this.user.clients.length === 1 && this.user.clients[0].configs && this.user.clients[0].configs.display_logo_in_app) {
        return this.user.clients[0].configs.interface_logo_url
          ? this.user.clients[0].configs.interface_logo_url : this.user.clients[0].logo_url
      }
      return null
    },
    showSnapcall() {
      return this.$store.getters['snapcall/showSnapcall']
    },
    snapcallId() {
      return this.$store.getters['snapcall/snapcallId']
    }
  },
  async mounted () {
    if(this.initialClient) {
      await this.$store.dispatch('apps/getApps', this.initialClient['id'])
      this.currentSwitchClient = this.initialClient
      this.$store.dispatch('snapcall/setSnapcallId', this.currentSwitchClient.configs.snapcall_id)
    }
  },
  methods: {
    goHome () {
      let writeParams = {}

      this.$store.dispatch('order/hardReset')
      this.$store.dispatch('order/list', { ...this.$store.getters['order/params'], l: 20 })

      for (var key in this.params) {
        if (this.params[key] &&
          ((key === 'channelId' && this.params[key] !== 1) || key !== 'channelId')) {
          writeParams[key] = this.params[key]
        }
      }
      this.$router.replace({ query: writeParams })
    },
    updateChannel (newChannel) {
      if (this.params.channelId !== newChannel) {
        this.$store.dispatch('order/params', { channelId: newChannel })
        if ((newChannel === this.refChannels.EMAIL && !this.isEmailsLoaded) ||
          (newChannel === this.refChannels.MOBILE && !this.isMobilesLoaded) ||
          (newChannel === this.refChannels.PUSH_NOTIF && !this.isPushNotifsLoaded)) {
          this.$store.dispatch('order/list', { ...this.params, l: 20 })
        }

        // Reload order count no matter if already loaded
        this.$store.dispatch('order/countOrderInError', { channelId: this.params.channelId })
      }
      this.injectQueryParams()
    },
    injectQueryParams () {
      let writeParams = {}
      const availableStatus = []
      for (let key in config.SENDING_ORDER_STATUS) {
        availableStatus.push(config.SENDING_ORDER_STATUS[key])
      }

      // Before write params and replace the url we want to ensure params are valid
      for (let key in this.params) {
        if (key === 'channelId') {
          if (this.params.channelId !== 1 && parseInt(this.params.channelId)) {
            writeParams.channelId = this.params.channelId
          }
        } else if (key === 'status') {
          writeParams.status = get(this.params, `status.split(',')`, [])
            .map((x) => parseInt(x))
            .filter(value => availableStatus.includes(value))
            .join(',')
        } else {
          writeParams[key] = this.params[key]
        }
      }
      this.$router.replace({ query: writeParams })
    },
    handleCommand (command) {
      switch (command) {
        case 'logout':
          localStorage.removeItem('auth/user')
          this.$store.dispatch('auth/logout')
          break
        case 'profile':
          window.open('/profile')
          break
      }
    },
    getInitials (user) {
      if (!user || !user.first_name || !user.last_name) {
        return ''
      }
      let name = user.first_name + ' ' + user.last_name
      let initials = name.match(/\b\w/g) || []
      initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
      return initials
    },
    async updateSwitchClient (client) {
      await this.$store.dispatch('apps/getApps', client ? client['id'] : null)
      this.currentSwitchClient = client
    },
    launchSnapcall() {
      if (this.showSnapcall) {
        this.$store.dispatch('snapcall/endSnapcall')
      } else {
        this.$store.dispatch('snapcall/startSnapcall')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .desktop-header-bar {
    z-index: 2000;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $--color-primary;
    color: #333;
    text-align: center;
    height: 48px !important;
  }

  .header-middle {
    display: flex;
    margin-left: 30px;
    .menu-link.el-button--text {
      margin: 0 20px;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      display: inline-block;
      color: rgba(255,255,255,.8);
      transition: all .2s;
      &.is-selected,
      &:hover {
        color: white;
      }
    }
  }

  .header {
    .page-title {
      margin-left:15px;
      font-size: 18px;
      color: white;
    }
    .el-button.el-button--text {
      padding: 7px;
    }
    .header-button {
      margin: 0 10px;
    }
    .header-button i {
      font-size: 22px;
      line-height: 15px;
    }

    .header-account.el-button.el-button--text {
      padding: 3px;
    }

    .header-bar-edit {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .desktop-header {
      padding-right: 30px;
    }

    .mobile-header {
      padding-right: 0px;
    }

    .home-btn {
      height: 48px;
      border: 0;
      border-radius: 0 !important;
      background-color: #1b2533;

      &:hover, &:focus {
        background-color: #1b2533;
      }
    }

    .header-bar-edit .white-btn {
      &, & .el-button--text {
        color: rgba(255, 255, 255, 1);
      }
      background-color: 2px;
      border-radius: 4px;
      transition: .1s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    .icon-preview {
      vertical-align: text-bottom;
      font-size: 14px;
      padding-right: 5px;
      padding-left: 10px;
    }

    .el-button-group {

      &:hover {
        color: $--color-primary;
      }

      .el-button {
        color: inherit;
        font-weight: inherit;
        font-size: inherit;

      }
      i {
        vertical-align: text-bottom;
        font-size: 14px;
        padding-right: 5px;
        padding-left: 10px;
      }

      .el-dropdown__caret-button::before {
        background: transparent;
      }
    }
  }
  .el-dropdown-menu.menu-account {
    padding: 0;
  }

  .footer {
    .footer-middle {
      .preview-btn-mobile {
        &, & .el-button--text {
          color: rgba(255, 255, 255, 1);
        }
      }
      .icon-preview {
        vertical-align: text-bottom;
        font-size: 14px;
        padding-right: 5px;
        padding-left: 10px;
      }
    }
  }

</style>
