import Vue from 'vue'

const SET_CLIENTS = 'SET_CLIENTS'

const moduleClients = {
  namespaced: true,
  state: {
    clients: [],
  },
  mutations: {
    [SET_CLIENTS] (state, clients) {
      Vue.set(state, 'clients', clients)
    },
  },
  actions: {
    async getClients ({ commit }) {
        const url = '/gate/clients'
        return new Promise((resolve, reject) => {
          Vue.axios.get(url)
            .then(response => {
              if (response.status === 200) {
                commit(SET_CLIENTS, response.data)
                resolve(response)
              } else {
                reject(response)
              }
            })
            .catch(error => {
              reject(error.response ? error.response : error)
            })
        })
    }
  },
  getters: {
    clients: state => state.clients,
  }
}

export default moduleClients
