import { orderBy as _orderBy } from 'lodash-es'
import { filterGroupsColor } from '@/assets/styles/numberly-filters.scss'

function formatGroupToOption(group) {
  return {
    label: group.name,
    value: { id: group.id, name: group.name }
  }
}

const filterGroups = {
  data () {
    return {
      filterGroupsColor: filterGroupsColor
    }
  },
  methods: {
    /**
     * Get selected groups ids and init the group filter cascade
     */
    initSelectedGroups (allGroups, defaultSelectedGroups = []) {
      let selectedGroups = []
      let optionGroups = allGroups.map(formatGroupToOption)
      let groupIds = defaultSelectedGroups.map(g => g.id)

      if (this.$route.query.group_ids) {
        groupIds = this.$route.query.group_ids.split(',')
      }

      optionGroups = _orderBy(optionGroups, [group => group.label.toLowerCase()])

      if (groupIds.length) {
        selectedGroups = optionGroups
            .filter(opt => groupIds.includes(opt.value.id))
            .map(g => [g.value])
      }

      return { options: optionGroups, selected: selectedGroups }
    },

    /**
     * Format generated value from the cascader selection to be used externally of the Filter mechanism
     */
    formatGroupsCascader (cascaderGroupsSelection) {
      return cascaderGroupsSelection.flat() || []
    }
  }
}

export default filterGroups
