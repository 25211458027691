import { orderBy as _orderBy } from 'lodash-es'
import { filterClientsColor } from '@/assets/styles/numberly-filters.scss'

function formatClientToOption(client) {
  return {
    label: client.name,
    value: { id: client.id, name: client.name }
  }
}

const filterClients = {
  data () {
    return {
      filterClientsColor: filterClientsColor
    }
  },
  methods: {
    /**
     * Get selected clients ids and init the client filter cascade
     */
    initSelectedClients (allClients, defaultSelectedClients = []) {
      let selectedClients = []
      let optionClients = allClients.map(formatClientToOption)
      let clientIds = defaultSelectedClients.map(g => g.id)

      if (this.$route.query.group_ids) {
        clientIds = this.$route.query.group_ids.split(',')
      }

      optionClients = _orderBy(optionClients, [group => group.label.toLowerCase()])

      if (clientIds.length) {
        selectedClients = optionClients
            .filter(opt => clientIds.includes(opt.value.id))
            .map(g => [g.value])
      }

      return { options: optionClients, selected: selectedClients }
    },

    /**
     * Format generated value from the cascader selection to be used externally of the Filter mechanism
     */
    formatClientsCascader (cascaderClientsSelection) {
      return cascaderClientsSelection.flat() || []
    }
  }
}

export default filterClients
