<template>
  <div style="min-width:830px;">
    <Header :scrolled="scrollDownDetected" />
    <div
      ref="body"
      class="body"
      @scroll="handleScroll"
    >
      <div class="body-inner">
        <OrderHeader
          :value="params"
          :errors-count="errorsInChannel"
          :current-channel-id="params.channelId"
          @input="updateParams"
        />
        <PartOrderList
          :is-filtered="isFiltered"
          :orders="currentOrders"
          :load-more="loadMore"
          :can-load-more="canLoadMore"
          :is-loading-more="loadingMoreOrder"
          @open-order-card="openOrderCard"
          @open-campaign-pause-modal="openCampaignPauseModal"
        />
        <PartOrderDetails
          :order="order"
          :state="modalDetailState"
          :is-loading="orderDetailIsLoading"
          :is-open="modalDetailIsOpen"
          :is-cancelled="isCancelled"
          @change-state="updateState"
          @close-modal="closeOrderCardModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/UI/Header'
import OrderHeader from '@/components/Order/Header'
import PartOrderList from '@/components/Part/OrderList'
import PartOrderDetails from '@/components/Part/OrderDetails'
import config from '@/config'
import { detailsOrder } from '@/mixins/order'

export default {
  name: 'ViewOrdersList',
  components: {
    PartOrderList,
    PartOrderDetails,
    Header,
    OrderHeader
  },
  mixins: [detailsOrder],
  data () {
    return {
      refChannels: config.CHANNELS,
      scrollDownDetected: false,
      scrollEventListener: null
    }
  },
  computed: {
    params () {
      return this.$store.getters['order/params']
    },
    order () {
      return this.$store.getters['order/order']
    },
    emailOrders () {
      return this.$store.getters['order/emails']
    },
    mobileOrders () {
      return this.$store.getters['order/mobiles']
    },
    loadingMoreOrder () {
      return this.$store.getters['order/isLoadingMore'] || this.$store.getters['order/isLoading']
    },
    pushNotifOrders () {
      return this.$store.getters['order/pushNotifs']
    },
    isEmailsLoaded () {
      return this.$store.getters['order/isEmailsLoaded']
    },
    isMobilesLoaded () {
      return this.$store.getters['order/isMobilesLoaded']
    },
    isCancelled () {
      return this.$store.getters['order/isCancelled']
    },
    isPushNotifsLoaded () {
      return this.$store.getters['order/isPushNotifsLoaded']
    },
    isEmailsAllLoaded () {
      return this.$store.getters['order/isEmailsAllLoaded']
    },
    isMobilesAllLoaded () {
      return this.$store.getters['order/isMobilesAllLoaded']
    },
    isPushNotifsAllLoaded () {
      return this.$store.getters['order/isPushNotifsAllLoaded']
    },
    errorsInChannel () {
      return this.$store.getters['order/errorsInChannel'](this.params.channelId)
    },
    canLoadMore () {
      const currentChannel = this.params.channelId
      if (currentChannel === this.refChannels.EMAIL) {
        return !this.isEmailsAllLoaded
      } else if (currentChannel === this.refChannels.MOBILE) {
        return !this.isMobilesAllLoaded
      } else if (currentChannel === this.refChannels.PUSH_NOTIF) {
        return !this.isPushNotifsAllLoaded
      }
      return false
    },
    // Return order filtered on current channelId
    currentOrders () {
      if (this.params.channelId === this.refChannels.EMAIL) {
        return this.emailOrders
      } else if (this.params.channelId === this.refChannels.MOBILE) {
        return this.mobileOrders
      } else if (this.params.channelId === this.refChannels.PUSH_NOTIF) {
        return this.pushNotifOrders
      }
      return []
    },
    isFiltered () {
      return !!(this.params.s && this.params.s.length > 0)
    }
  },
  created () {
    // TODO(@cky): fix reactivity
    this.extractQueryParams()
    this.$store.dispatch('order/list', { ...this.params, l: 20 })

    this.emailFluxId = setInterval(() => {
      if (this.emailOrders && this.emailOrders.length > 0) {
        this.$store
          .dispatch('order/flux', {
            channelId: this.refChannels.EMAIL,
            ids: this.emailOrders.map(({ id }) => id),
            from: this.emailOrders[this.emailOrders.length - 1].sending_date,
            params: this.params
          })
      }
    }, config.ORDER_POLLING_RATE)

    this.mobileFluxId = setInterval(() => {
      if (this.mobileOrders && this.mobileOrders.length > 0) {
        this.$store
          .dispatch('order/flux', {
            channelId: this.refChannels.MOBILE,
            ids: this.mobileOrders.map(({ id }) => id),
            from: this.mobileOrders[this.mobileOrders.length - 1].sending_date,
            params: this.params
          })
      }
    }, config.ORDER_POLLING_RATE)

    this.pushNotifFluxId = setInterval(() => {
      if (this.pushNotifOrders && this.pushNotifOrders.length > 0) {
        this.$store
          .dispatch('order/flux', {
            channelId: this.refChannels.PUSH_NOTIF,
            ids: this.pushNotifOrders.map(({ id }) => id),
            from: this.pushNotifOrders[this.pushNotifOrders.length - 1].sending_date,
            params: this.params
          })
      }
    }, config.ORDER_POLLING_RATE)

    this.errorsWatchId = setInterval(() => {
      this.$store.dispatch('order/countOrderInError', { channelId: this.params.channelId })
    }, config.ORDER_POLLING_RATE)

    // Execute immediatly
    this.$store.dispatch('order/countOrderInError', { channelId: this.params.channelId })
  },
  mounted() {
    this.scrollEventListener = this.$refs['body']
      .addEventListener("scroll", this.handleScroll)
  },
  beforeDestroy () {
    clearInterval(this.emailFluxId)
    clearInterval(this.mobileFluxId)
    clearInterval(this.pushNotifFluxId)
    clearInterval(this.errorsWatchId)
    this
      .$refs['body']
      .removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll () {
      this.scrollDownDetected = this.$refs['body'].scrollTop > 48
    },
    updateState (state) {
      const currentChannel = this.params.channelId
      this.modalDetailState = state
      if (currentChannel === this.refChannels.EMAIL) {
        this.$store
            .dispatch('order/flux', {
              channelId: this.refChannels.EMAIL,
              ids: this.emailOrders.map(({ id }) => id),
              from: this.emailOrders[this.emailOrders.length - 1].sending_date,
              params: this.params
            })
      } else if (currentChannel === this.refChannels.MOBILE) {
        this.$store
            .dispatch('order/flux', {
              channelId: this.refChannels.MOBILE,
              ids: this.mobileOrders.map(({ id }) => id),
              from: this.mobileOrders[this.mobileOrders.length - 1].sending_date,
              params: this.params
            })
      } else if (currentChannel === this.refChannels.PUSH_NOTIF) {
        this.$store
          .dispatch('order/flux', {
            channelId: this.refChannels.PUSH_NOTIF,
            ids: this.pushNotifOrders.map(({ id }) => id),
            from: this.pushNotifOrders[this.pushNotifOrders.length - 1].sending_date,
            params: this.params
          })
      }
    },
    updateFilters () {
      this.$store.dispatch('order/reset')
      this.$store.dispatch('order/list', { ...this.params, l: 20 })
    },
    loadMore () {
      if (!this.loadingMoreOrder) {
        const currentChannel = this.params.channelId
        if ((currentChannel === this.refChannels.EMAIL && !this.isEmailsAllLoaded) ||
          (currentChannel === this.refChannels.MOBILE && !this.isMobilesAllLoaded) ||
          (currentChannel === this.refChannels.PUSH_NOTIF && !this.isPushNotifsAllLoaded)) {
          this.$store.dispatch('order/listExtend', { ...this.params, l: 20 })
        }
      }
    },
    updateParams (value) {
      this.$store.dispatch('order/params', value)
      this.injectQueryParams()
      this.updateFilters()
    },
    injectQueryParams () {
      let writeParams = {}
      const availableStatus = []
      for (let key in config.SENDING_ORDER_STATUS) {
        availableStatus.push(config.SENDING_ORDER_STATUS[key])
      }

      // Before write params and replace the url we want to ensure params are valid
      for (let key in this.params) {
        switch (key) {
          case 'channelId': {
            if (this.params[key] !== 1 && parseInt(this.params[key])) {
              writeParams.channelId = this.params[key]
            }
            break
          }
          case 'status': {
            if (this.params[key].length) {
              writeParams[key] = this.params[key].split(',')
                .map(v => parseInt(v))
                .filter(value => availableStatus.includes(value))
                .join(',')
            }
            break
          }
          case 'clients': {
            if (this.params[key].length) {
              writeParams[key] = this.params[key].map(v => v.id).join(',')
            }
            break
          }
          case 'groups': {
            if (this.params[key].length) {
              writeParams[key] = this.params[key].map(v => v.id).join(',')
            }
            break
          }
          default: {
            if (this.params[key].length) {
              writeParams[key] = this.params[key]
            }
          }
        }
      }
      this.$router.replace({ query: writeParams })
    },
    extractQueryParams () {
      let queryParams = this.$route.query
      const availableStatus = []
      for (let key in config.SENDING_ORDER_STATUS) {
        availableStatus.push(config.SENDING_ORDER_STATUS[key])
      }

      if ('channelId' in queryParams) {
        queryParams.channelId = parseInt(queryParams['channelId'])
      }
      // Ensure all status param are correct
      if ('status' in queryParams) {
        const statusParams = queryParams.status.split(',').map(v => (parseInt(v)))
        queryParams['status'] = statusParams.filter(v => availableStatus.includes(v)).join(',')
      }

      queryParams.clients = queryParams.clients ? queryParams.clients.split(',').map(v => ({ 'id': v })) : []
      queryParams.groups = queryParams.groups ? queryParams.groups.split(',').map(v => ({ 'id': v })) : []
      this.$store.dispatch('order/params', queryParams)
    },
    openCampaignPauseModal (order) {
      const action = order.status_id == config.SENDING_ORDER_STATUS.PAUSED ? 'resume' : 'pause'
      this.openOrderCard(order.id, action)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

h1 .subtitle {
  margin-top: 10px;
  font-size: 14px;
  color: #222;
  font-weight: 400;
}

.el-loading-spinner {
  margin-top: 0px !important;
}

.body {
  position: absolute;
  overflow-y: scroll;
  width: 100%;
  top: 48px;
  right: 0;
  left: 0;
  bottom: 0;
  .body-inner {
    min-width: 800px;
    max-width: 1300px;
    padding: 0 32px 30px;
    margin: 40px auto 0;
  }
}

.main-container {
  padding: 30px 0 0;
}
</style>
