<template>
  <div>
    <div v-if="startedOrders.length">
      <div class="separator">
        {{ $t("MONITORING.CURRENT_ACTIVITY") }}
      </div>
      <div class="list-container">
        <el-row
          v-if="!isLoading"
          :gutter="10"
        >
          <el-col
            v-for="order in ongoingOrders"
            :key="order.id"
            :span="4"
            :xs="12"
            :sm="8"
            :md="4"
            :lg="4"
          >
            <monitoring-card
              :order="order"
              @open-details="(value) => $emit('open-order-card', value)"
            />
          </el-col>
          <el-col
            v-if="displayMoreActivity"
            :span="4"
            :xs="12"
            :sm="8"
            :md="4"
            :lg="4"
          >
            <el-button
              class="button-show-more"
              @click="activityToggle = !activityToggle"
            >
              <div class="center-left-align">
                <div>
                  <div v-if="startedOrdersInError && !activityToggle">
                    {{
                      $tc('MONITORING.SHOW_MORE_ERRORS', startedOrdersInError, startedOrdersInError)
                    }}
                  </div>
                  {{ !activityToggle ? buttonMoreActivity : $t('MONITORING.SHOW_LESS') }}
                </div>
              </div>
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="scheduledOrders.length">
      <div class="separator">
        {{ $t("MONITORING.SCHEDULED") }}
      </div>
      <div class="list-container">
        <el-row
          v-if="!isLoading"
          :gutter="10"
        >
          <el-col
            v-for="order in comingOrders"
            :key="order.id"
            :span="4"
            :xs="12"
            :sm="8"
            :md="4"
            :lg="4"
          >
            <monitoring-card
              :order="order"
              @open-details="(value) => $emit('open-order-card', value)"
            />
          </el-col>
          <el-col
            v-if="displayMoreScheduled"
            :span="4"
            :xs="12"
            :sm="8"
            :md="4"
            :lg="4"
          >
            <el-button
              class="button-show-more scheduled"
              @click="scheduledToggle = !scheduledToggle"
            >
              {{ !scheduledToggle ? buttonMoreScheduled : $t('MONITORING.SHOW_LESS') }}
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import MonitoringCard from '@/components/Monitoring/Card'
import config from '@/config'

export default {
  components: {
    MonitoringCard
  },
  props: {
    scheduledOrders: {
      type: Array,
      required: true
    },
    startedOrders: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      limitActivity: 24,
      limitScheduled: 5,
      activityToggle: false,
      scheduledToggle: false
    }
  },
  computed: {
    isLoading () {
      return this.$store.getters['order/isLoading']
    },
    ongoingOrders () {
      if (!this.activityToggle) {
        return this.startedOrders.slice(0, this.limitActivity)
      } else return this.startedOrders
    },
    comingOrders () {
      if (!this.scheduledToggle) {
        return this.scheduledOrders.slice(0, this.limitScheduled)
      } else return this.scheduledOrders
    },
    startedOrdersInError () {
      return this.startedOrders.filter((el, index) => {
        return el.status_id === config.SENDING_ORDER_STATUS.ERROR &&
          index + 1 > this.limitActivity
      }).length
    },
    displayMoreScheduled () {
      return this.scheduledOrders.length > this.limitScheduled
    },
    displayMoreActivity () {
      return this.startedOrders.length > this.limitActivity
    },
    buttonMoreScheduled () {
      const value = this.scheduledOrders.length - this.limitScheduled
      return (
        this.$tc('MONITORING.SHOW_MORE_SCHEDULED', value, value)
      )
    },
    buttonMoreActivity () {
      const value =
        (this.startedOrders.length - this.limitActivity - this.startedOrdersInError)
      return (
        this.$tc('MONITORING.SHOW_MORE_ONGOING', value, value)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
  .list-container {
    margin: 26px 0;
  }
  .scheduled {
    height: 100px !important;
  }
  .button-show-more {
    background-color: $--color-button-grey;
    color: $--color-black;
    font-size: 21px;
    font-weight: 400;
    height: 145px;
    width: 100%;
    .center-left-align {
      display: flex;
      justify-content: center;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        div {
          padding-bottom: 6px;
        }
      }
    }
  }
  .separator {
    display: flex;
    font-size: 24px;
    font-weight: 400px;
    align-items: center;
    &:after {
      content: '';
      flex: 1;
      border-bottom: 1px solid $--color-separator;
      margin-left: 25px;
    }
  }
</style>
