import config from '@/config'

const statusOrder = {
  computed: {
    status () {
      let orderState = 'SCHEDULED'
      if (this.isError) {
        orderState = this.isResendInProgress ? 'PREPARING_RESEND': 'IN_ERROR'
      } else if (this.isSent) {
        orderState = 'SENT'
      } else if (this.isPaused) {
        orderState = 'PAUSE'
      } else if (this.isInterrupted) {
        orderState = 'INTERRUPTED'
      } else if (this.isReadyToSend) {
        orderState = 'READY_TO_SEND'
      } else if (this.isStarted) {
        orderState = this.isProcessing ? 'PROCESSING': 'IN_PROGRESS'
      }
      return `ORDER_STATUS_${orderState}`
    },
    statusClass () {
      let classValue = 'info'
      switch (this.order.status_id) {
        case config.SENDING_ORDER_STATUS.SCHEDULED:
          if (this.isPaused) {
            classValue = 'warning'
          }
          break
        case config.SENDING_ORDER_STATUS.SENDING_IN_PROGRESS:
          classValue = this.isPaused ? 'warning' : 'primary'
          break
        case config.SENDING_ORDER_STATUS.SENT:
          classValue = 'success'
          break
        case config.SENDING_ORDER_STATUS.ERROR:
          classValue = 'danger'
          break
      }
      return classValue
    },
    isProcessing () {
      const isFinished = this.order.processing_status == config.PROCESSING_ORDER_STATUS.END
      const isStopped = this.order.processing_status == config.PROCESSING_ORDER_STATUS.CANCELLED || this.order.processing_status == config.PROCESSING_ORDER_STATUS.CLEANING
      return !this.isError &&  !isFinished &&  !isStopped
    },
    isSent () {
      return this.order.status_id === config.SENDING_ORDER_STATUS.SENT
    },
    isPaused () {
      return this.order.status_id === config.SENDING_ORDER_STATUS.PAUSED
    },
    isScheduled () {
      return this.order.status_id === config.SENDING_ORDER_STATUS.SCHEDULED
    },
    isStarted () {
      return (
        this.order.status_id === config.SENDING_ORDER_STATUS.SENDING_IN_PROGRESS
      )
    },
    isError () {
      return this.order.status_id === config.SENDING_ORDER_STATUS.ERROR
    },
    isInterrupted () {
      return this.order.status_id === config.SENDING_ORDER_STATUS.SENDING_IN_PROGRESS &&
        this.order.is_interrupted
    },
    isReadyToSend () {
      return this.order.status_id === config.SENDING_ORDER_STATUS.SENDING_IN_PROGRESS &&
        this.order.processing_status == config.PROCESSING_ORDER_STATUS.READY
    },
    isResendInProgress () {
      return this.order.error && this.order.error.resent_status === config.RESEND_STATUS.ONGOING
    },
    sendingDateReached () {
      return new Date(this.order.sending_date) <=  new Date()
    },
    progressColor () {
      if (!this.isOnline) {
        return '#A6A6BC'
      } else if (this.isError) {
        return '#F0645F'
      } else if (this.isPaused) {
        return '#FE9359'
      } else if (this.isStarted) {
        return 'rgba(74, 144, 226, 1)'
      } else {
        return 'rgba(31, 178, 111, 1)'
      }
    }
  }
}

const detailsOrder = {
  data () {
    return {
      // Data linked to Modal order detail
      orderDetail: {},
      orderDetailIsLoading: false,
      modalDetailIsOpen: false,
      modalDetailState: 'detail'
    }
  },
  methods: {
    openOrderCard (orderId, modalDetailState = 'detail') {
      // We can preload half of the data in many case
      this.orderDetail = this.currentOrders.find(o => o.id === orderId) || {}
      this.modalDetailState = modalDetailState
      this.$store.dispatch('order/preload', this.orderDetail)
      this.orderDetailIsLoading = true
      this.modalDetailIsOpen = true
      this.$store
        .dispatch('order/details', { orderId: orderId })
        .then(order => {
          this.orderDetail = order.data
          this.orderDetailIsLoading = false
        })
        .catch(error => {
          let errNotif = {
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          }
          if (error.status === 404) {
            errNotif.title = this.$t('ERRORS.CAMPAIGNS_NOT_FOUND')
            errNotif.description = ''
          }
          this.modalDetailIsOpen = false
          this.$notify(errNotif)
        })
        .finally(() => {
          this.orderDetailIsLoading = false
        })
    },
    closeOrderCardModal () {
      this.modalDetailIsOpen = false
      setTimeout(() => {
        this.orderDetail = {}
      }, 300)
    }
  }
}

export { statusOrder, detailsOrder }
