<template>
  <div class="empty-box">
    <img
      :src="icon"
      alt="Empty icon"
    >
    <br>
    <div class="title-box">
      {{ $t(title) }}
    </div>
  </div>
</template>

<script>
import noData from '@/assets/img/box.svg'
import searchEmpty from '@/assets/img/search.svg'

export default {
  name: 'UiEmpty',
  props: {
    state: {
      type: String,
      required: true,
      validator: (value) => {
        return ['no-data', 'search-empty'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    icon () {
      let picto = noData
      if (this.state === 'search-empty') {
        picto = searchEmpty
      }
      return picto
    },
    title () {
      let titleKey = 'DEFAULT_TITLE'
      if (this.state === 'no-data') {
        titleKey = 'NO_DATA_TITLE'
      } else if (this.state === 'search-empty') {
        titleKey = 'SEARCH_EMPTY_TITLE'
      }
      return `EMPTY.${titleKey}`
    }
  }
}
</script>

<style lang="scss">
.empty-box {
  background: url('~@/assets/img/bg.svg') no-repeat;
  background-size: cover;
  background-position: center;
  width: 403px;
  height: 387px;
  text-align: center;
  margin: 0 auto;

  img {
    width: 70px;
    margin-top: 110px
  }

  .title-box {
    margin-top: 30px;
    font-size: 18px;
    padding: 0 40px;
    color: #333;
  }
}
</style>
