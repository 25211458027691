import config from '@/config'
import { filterStatusColor } from '@/assets/styles/numberly-filters.scss'

const filterStatus = {
  data () {
    return {
      filterStatusColor: filterStatusColor,
      statusScheduled: {
        label: this.$t('ORDER_STATUS_SCHEDULED'),
        value: {
          id: config.SENDING_ORDER_STATUS.SCHEDULED,
          name: this.$t('ORDER_STATUS_SCHEDULED')
        }
      },
      statusInProgress: {
        label: this.$t('ORDER_STATUS_IN_PROGRESS'),
        value: {
          id: config.SENDING_ORDER_STATUS.SENDING_IN_PROGRESS,
          name: this.$t('ORDER_STATUS_IN_PROGRESS')
        }
      },
      statusInError: {
        label: this.$t('ORDER_STATUS_IN_ERROR'),
        value: {
          id: config.SENDING_ORDER_STATUS.ERROR,
          name: this.$t('ORDER_STATUS_IN_ERROR'),
        },
        errorCount: this.errors
      },
      statusSent: {
        label: this.$t('ORDER_STATUS_SENT'),
        value: {
          id: config.SENDING_ORDER_STATUS.SENT,
          name: this.$t('ORDER_STATUS_SENT')
        }
      },
      statusPaused: {
        label: this.$t('ORDER_STATUS_PAUSE'),
        value: {
          id: config.SENDING_ORDER_STATUS.PAUSED,
          name: this.$t('ORDER_STATUS_PAUSE')
        }
      }
    }
  },
  methods: {

    /**
     * Update error value of status in error
     */
    updateStatusErrors (value) {
      this.statusInError.errorCount = value
    },

    /**
     * Get selected status ids, remove tags ids and init the status filter cascade
     */
     initSelectedStatus (defaultSelectedStatus = []) {
       const optionStatus = [this.statusScheduled, this.statusInProgress, this.statusInError, this.statusSent, this.statusPaused]
       let selectedStatus = []
      if (defaultSelectedStatus.length) {
        selectedStatus = optionStatus
          .filter(opt => defaultSelectedStatus.includes(opt.value.id))
          .map(t => [t.value])
      }
      return { options: optionStatus, selected: selectedStatus }
    },

    /**
     * Format generated value from the cascader selection to be used externally of the Filter mechanism
     */
    formatStatusCascader (cascaderStatusSelection) {
      return cascaderStatusSelection.flat().map(v => v.id).join(',')
    }
  }
}

export default filterStatus
