<template>
  <div
    v-if="!isLoading && isAllOrdersLoaded"
    class="monitoring-container"
  >
    <is-offline class="monitoring-offline" />
    <!-- header -->
    <monitoring-header
      class="monitoring-header"
      :errors="ordersInError"
      :in-progress="ordersInProgress"
      :scheduled="ordersToCome"
      :sent="ordersSent"
    />
    <empty
      v-if="!isLoading && isOrdersEmpty"
      :state="'no-data'"
    />
    <monitoring-list
      v-else
      :scheduled-orders="scheduledOrders"
      :started-orders="startedOrders"
      @open-order-card="openOrderCard"
    />
    <PartOrderDetails
      :order="orderDetail"
      :state="modalDetailState"
      :is-loading="orderDetailIsLoading"
      :is-open="modalDetailIsOpen"
      :is-monitoring="true"
      @change-state="(state) => modalDetailState = state"
      @close-modal="closeOrderCardModal"
    />
  </div>
</template>

<script>
import Empty from '@/components/UI/Empty'
import MonitoringHeader from '@/components/Monitoring/Header'
import MonitoringList from '@/components/Part/MonitoringList'
import PartOrderDetails from '@/components/Part/OrderDetails'
import IsOffline from '@/components/UI/IsOffline'
import { detailsOrder } from '@/mixins/order'
import config from '@/config'

export default {
  name: 'ViewMonitoring',
  components: {
    PartOrderDetails,
    MonitoringHeader,
    MonitoringList,
    Empty,
    IsOffline
  },
  mixins: [detailsOrder],
  data () {
    return {
      orderFluxId: null
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    currentOrders () {
      return this.$store.getters['order/allOrders'].filter(el => {
        return this.canBeDisplay(el)
      }).sort((a, b) => {
        return (
          moment(a.sending_date).tz(this.user.timezone) -
          moment(b.sending_date).tz(this.user.timezone)
        )
      })
    },
    isAllOrdersLoaded () {
      return this.$store.getters['order/isAllOrdersLoaded']
    },
    isLoading () {
      return this.$store.getters['order/isLoading']
    },
    isOrdersEmpty () {
      return this.currentOrders.length === 0
    },
    ordersInError () {
      return this.currentOrders.filter(el => {
        return el.status_id === config.SENDING_ORDER_STATUS.ERROR
      }).length
    },
    ordersSent () {
      return this.currentOrders.filter(el => {
        return el.status_id === config.SENDING_ORDER_STATUS.SENT
      }).length
    },
    ordersToCome () {
      return this.currentOrders.filter(el => {
        return el.status_id === config.SENDING_ORDER_STATUS.SCHEDULED
      }).length
    },
    ordersInProgress () {
      return this.currentOrders.filter(el => {
        return el.status_id === config.SENDING_ORDER_STATUS.SENDING_IN_PROGRESS
      }).length
    },
    scheduledOrders () {
      return this.currentOrders.filter(el => {
        return el.status_id === config.SENDING_ORDER_STATUS.SCHEDULED
      })
    },
    startedOrders () {
      return this.currentOrders.filter(el => {
        return (
          el.status_id === config.SENDING_ORDER_STATUS.SENDING_IN_PROGRESS ||
            el.status_id === config.SENDING_ORDER_STATUS.ERROR
        )
      })
        .sort((a, b) => {
          return (
            (b.status_id === config.SENDING_ORDER_STATUS.ERROR) -
            (a.status_id === config.SENDING_ORDER_STATUS.ERROR)
          )
        })
    }
  },
  async created () {
    await this.$store.dispatch('order/list', { global: true, l: 50 })
    while (!this.isAllOrdersLoaded) {
      await this.$store.dispatch('order/listExtend', { global: true, l: 50 })
    }
    this.orderFluxId = setInterval(() => {
      if (this.currentOrders && this.currentOrders.length > 0) {
        this.$store
          .dispatch('order/flux', {
            global: true,
            ids: this.currentOrders.map(({ id }) => id),
            from: this.currentOrders[0].sending_date
          })
      }
    }, config.ORDER_POLLING_RATE)
  },
  beforeDestroy () {
    clearInterval(this.orderFluxId)
  },
  methods: {
    canBeDisplay (order) {
      const sendingDate = moment(order.sending_date).tz(this.user.timezone)
      const diffHours = moment().tz(this.user.timezone).diff(sendingDate, 'hours')

      const haveRequiredStatus =
        order.status_id === config.SENDING_ORDER_STATUS.ERROR ||
        order.status_id === config.SENDING_ORDER_STATUS.SENDING_IN_PROGRESS

      return diffHours < 24 || haveRequiredStatus
    }
  }
}
</script>

<style lang="scss" scoped>
  .monitoring {
    &-container {
      margin: 40px;
    }
    &-header {
      margin-bottom: 40px;
    }
    &-offline {
      margin-bottom: 30px;
    }
  }
</style>
