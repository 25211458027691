<template>
  <div
    class="container-wrapper"
    data-tested-as="filters"
  >
    <div class="header-container">
      <h1
        class="header-title"
        data-tested-as="channel-title"
      >
        {{ pageInformation.title }}
      </h1>
      <span
        class="header-descritpion"
        data-tested-as="channel-desc"
      >
        {{ pageInformation.desc }}
      </span>
    </div>
    <div class="main-container">
      <Filters
        :search="value.s || ''"
        :search-placeholder="$t('ORDER_HEADER_SEARCH')"
        :selected-filters="selectedFilters"
        @update-search="v => $emit('input', { 's': v })"
        @unselect-filter="unselectFilter"
        @unselect-all="unselectAllFilters"
      >
        <!-- GROUPS -->
        <FilterCascade
          v-if="isMultiClients"
          name="clients"
          data-tested-as="filter-groups"
          :color="filterGroupsColor"
          :values="dataClients.selected"
          :options="dataClients.options"
          @updated="updateSelectedClients"
        />
        <!-- GROUPS -->
        <FilterCascade
          v-else
          name="groups"
          data-tested-as="filter-groups"
          :color="filterGroupsColor"
          :values="dataGroups.selected"
          :options="dataGroups.options"
          @updated="updateSelectedGroups"
        />
        <!-- Status -->
        <el-badge
          data-tested-as="filter-status"
          :hidden="errorsCount < 1"
          :value="errorsCount"
        >
          <FilterCascade
            name="status"
            :color="filterStatusColor"
            :values="dataStatus.selected"
            :options="dataStatus.options"
            @updated="updateSelectedStatus"
          />
        </el-badge>
      </Filters>
      <div style="clear: both;" />
    </div>
  </div>
</template>

<script>
import filterClients from '@/mixins/filterClients'
import filterGroups from '@/mixins/filterGroups'
import filterStatus from '@/mixins/filterStatus'
import  FilterCascade from '@/components/UI/FilterCascade'
import  Filters from '@/components/UI/Filters'
import config from '@/config'
import { sortBy } from 'lodash-es'

export default {
  name: 'OrderHeader',
  components: {
    FilterCascade,
    Filters
  },
  mixins: [
    filterClients,
    filterGroups,
    filterStatus
  ],
  props: {
    currentChannelId: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    errorsCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      config,
      dataClients: {
        options: [],
        selected: []
      },
      dataGroups: {
        options: [],
        selected: []
      },
      dataStatus: {
        options: [],
        selected: []
      }
    }
  },
  computed: {
    pageInformation () {
      let translations
      switch (this.currentChannelId) {
        case config.CHANNELS.EMAIL:
          translations = {
            title: this.$t('CAMPAIGN_STATES_EMAIL'),
            desc: this.$t('CAMPAIGN_STATES_EMAIL_DESC')
          }
          break
        case config.CHANNELS.MOBILE:
          translations = {
            title: this.$t('CAMPAIGN_STATES_MOBILE'),
            desc: this.$t('CAMPAIGN_STATES_MOBILE_DESC')
          }
          break
        case config.CHANNELS.PUSH_NOTIF:
          translations = {
            title: this.$t('CAMPAIGN_STATES_PUSH_NOTIF'),
            desc: this.$t('CAMPAIGN_STATES_PUSH_NOTIF_DESC')
          }
          break
      }
      return translations
    },
    /**
     * Clients
     */
    isMultiClients () {
      return this.$store.getters['auth/isMultiClients']
    },
    clients () {
      return this.$store.getters['auth/userClients']
    },
    /**
     * Groups
     */
    groups () {
      const groups = this.$store.getters['auth/userGroups']
      if (groups.length > 1) return sortBy(groups, 'name')
      return groups
    },
    /**
     * Get all filters selected options to generate tags
     */
    selectedFilters () {
      return [
        ...this.dataClients.selected.map((tag, index) => ({
          type: 'clients', color: this.filterClientsColor, tag, index
        })),
        ...this.dataGroups.selected.map((tag, index) => ({
          type: 'groups', color: this.filterGroupsColor, tag, index
        })),
        ...this.dataStatus.selected.map((tag, index) => ({
          type: 'status', color: this.filterStatusColor, tag, index
        }))
      ]
    }
  },
  watch: {
    currentChannelId: function (newVal, oldVal) {
      if (newVal === oldVal) return
      this.dataStatus.selected = []
      this.$emit('input', {
        'status': this.dataStatus.selected
      })
    },
    errorsCount: function (value) {
      this.updateStatusErrors(value)
    }
  },
  /**
   * Init filters
   */
  mounted () {
    this.$nextTick(() => {
      this.dataClients = this.initSelectedClients(this.clients, this.value.clients)
      this.dataGroups = this.initSelectedGroups(this.groups, this.value.groups)
      let status = []
      if (this.value.status) { status = this.value.status.split(',') }
      this.dataStatus = this.initSelectedStatus(status.map(s => parseInt(s)))
    })
  },
  methods: {
    /**
     * Clear all filters
     */
    unselectAllFilters () {
      this.dataClients.selected = []
      this.dataGroups.selected = []
      this.dataStatus.selected = []

      this.$emit('input', {
        'clients': this.dataClients.selected,
        'groups': this.dataGroups.selected,
        'status': this.dataStatus.selected
      })
    },
    /**
     * On filter tag deletion, remove it from list of selected then trigger update
     * related to the filter type
     */
    unselectFilter (filter) {
      switch (filter.type) {
        case 'clients': {
          this.updateSelectedClients(
            this.dataClients.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
        case 'groups': {
          this.updateSelectedGroups(
            this.dataGroups.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
        case 'status': {
          this.updateSelectedStatus(
            this.dataStatus.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
      }
    },
    /**
     * Filter update sender
     */
    updateSelectedClients (values) {
      this.dataClients.selected = values
      this.$emit('input', { 'clients': this.formatClientsCascader(values) })
    },
    updateSelectedGroups (values) {
      this.dataGroups.selected = values
      this.$emit('input', { 'groups': this.formatGroupsCascader(values) })
    },
    updateSelectedStatus (values) {
      this.dataStatus.selected = values
      this.$emit('input', { 'status': this.formatStatusCascader(values) })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

::v-deep .el-input__prefix {
  display: flex;
  align-items: center;
}

.desktop-search-bar {
  float: left;
}

.desktop-filters {
  float: right;
}

.container-wrapper {
  background-color: #F8F9F8;
  top: 0;
  z-index: 100;
}

.header-container {
  .header-title,
  .header-descritpion {
    color: $--color-text;
    font-weight: 400;
    font-size: 16px;
  }
  .header-title {
    margin-bottom: 0 0 15px;
    font-size: 28px;
    font-weight: 300;
    font-family: $--text-title-family;
  }
}
</style>
