<template>
  <img
    :src="logoUrl"
    :style="styles"
    alt="Watchly logo"
  >
</template>

<script>
import logoWhite from '@/assets/img/logo_white.svg'
import logo from '@/assets/img/logo.svg'

export default {
  name: 'UiLogo',
  /**
   * Props the parent can use to manipulate this component.
   * Note: Components themselves should not mutate their own props.
   */
  props: {
    white: { type: Boolean, default: false },
    width: { type: Number, default: 100 },
    margin: { type: String, default: '0px' }
  },
  computed: {
    logoUrl () {
      return this.white ? logoWhite : logo
    },
    styles () {
      let css = ''
      let custom = false
      if (this.width) {
        css += 'max-width:' + this.width + 'px;'
        css += 'width:' + this.width + 'px;'
      }
      if (this.height) {
        css += 'max-height:' + this.height + 'px;'
        css += 'height:' + this.width + 'px;'
      }
      if (this.margin) {
        css += 'margin:' + this.margin + ';'
      }
      if (this.width || this.height || this.margin) {
        custom = true
      }
      if (!custom) {
        css += 'max-width:200px;'
      }
      css += 'cursor:pointer;'
      return css
    }
  }
}
</script>

<style lang="scss">

</style>
