<template>
  <div>
    <img
      v-if="order.is_generalization"
      :src="iconGene"
      class="margin-right-5"
      alt="icon generalization"
    >
    <span v-if="testWithOneCrmId">
      <span
        v-for="idx in order.nb_versions"
        :key="idx"
        class="label-version"
      >
        <div
          :class="versionName(idx - 1)"
          class="version label-version-name margin-right-5"
        >
          {{ versionNames[idx - 1] }}
        </div>
      </span>
      {{ uniqCrmIds[0] }}
    </span>
    <span v-else-if="testWithMultipCrmId">
      <span
        v-for="(idm, idx) in uniqCrmIds"
        :key="idx"
        class="label-version"
      >
        <div
          :class="versionName(idx)"
          class="version label-version-name"
        >
          {{ versionNames[idx] }}
        </div>
        <span>
          {{ idm }}<span v-if="idx < uniqCrmIds.length - 1">,</span>
        </span>
      </span>
    </span>
    <span v-else>
      {{ uniqCrmIds[0] }}
    </span>
  </div>
</template>

<script>
import { uniq } from 'lodash-es'
import iconGene from '@/assets/img/icon_gene.svg'
export default {
  name: 'DisplayCrmIds',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      versionNames: ['A', 'B', 'C', 'D', 'E']
    }
  },
  computed: {
    iconGene () {
      return iconGene
    },
    uniqCrmIds () {
      return uniq(this.order.crm_ids)
    },
    testWithMultipCrmId () {
      return (
        !this.order.is_generalization &&
        this.order.is_ab_test &&
        this.uniqCrmIds.length > 1
      )
    },
    testWithOneCrmId () {
      return (
        !this.order.is_generalization &&
        this.order.is_ab_test &&
        this.uniqCrmIds.length === 1
      )
    },
    crmAndNameVersions () {
      return this.uniqCrmIds.map((id, idx) => {
        return { 'crmId': id, 'versionName': this.versionNames[idx] }
      })
    }
  },
  methods: {
    versionName (index) {
      return `version-${index + 1}`
    }
  }
}
</script>
