import moduleOrders from './orders'
import moduleAuth from './auth'
import moduleApps from './apps'
import moduleClients from './clients'
import moduleSnapcall from './snapcall'

export default {
  order: moduleOrders,
  auth: moduleAuth,
  apps: moduleApps,
  clients: moduleClients,
  snapcall: moduleSnapcall
}
