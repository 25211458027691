<template>
  <div
    v-infinite-scroll="loadMore"
    :infinite-scroll-disabled="!canLoadMore || isLoadingMore"
    infinite-scroll-distance="10"
    infinite-scroll-immediate-check="false"
  >
    <div class="container-wrapper">
      <div
        class="main-container"
        data-tested-as="ordersList"
      >
        <div
          v-for="(order, index) in orders"
          :key="order.Id"
          class="order-day"
        >
          <h3
            v-if="isNewDay(index)"
            class="order-day-title"
          >
            {{ getDayAsHumanFormat(order) }}
          </h3>
          <OrderCard
            :key="order.Id"
            :order="order"
            style="margin-top:20px;"
            data-tested-as="order"
            @open-order-card="(value) => { $emit('open-order-card', value) }"
            @open-campaign-pause-modal="(value) => { $emit('open-campaign-pause-modal', value) }"
          />
        </div>

        <Empty
          v-if="orders.length == 0 && !isLoading"
          :state="isFiltered ? 'search-empty' : 'no-data'"
          class="margin-top-50 margin-bottom-50"
          data-tested-as="noDataImg"
        />

        <div v-loading="isLoadingMore" />
      </div>
    </div>
    <div
      v-show="!canLoadMore && orders.length > 0"
      class="all-activity-loaded text-center margin-top-15"
    >
      {{ $t('CAMPAIGNS_ACTIVITY_FULL_LOADED') }}
    </div>
  </div>
</template>

<script>
import Empty from '@/components/UI/Empty'
import OrderCard from '@/components/Order/Card'

export default {
  name: 'OrdersList',
  components: { OrderCard, Empty },
  props: {
    orders: { type: Array, required: true },
    isFiltered: { type: Boolean, default: false },
    loadMore: { type: Function, required: true },
    canLoadMore: { type: Boolean, default: true },
    isLoadingMore: { type: Boolean, default: false }
  },
  computed: {
    isLoading () {
      return this.$store.getters['order/isLoading']
    }
  },
  methods: {
    getDayAsHumanFormat (currentOrder) {
      let currentOrdersDate = moment(currentOrder.sending_date).tz('Europe/Paris')
      let currentDate = moment().startOf('day')
      const dateDiff = currentDate.diff(currentOrdersDate, 'days', true)
      if (dateDiff <= 0) {
        return this.$t('LIST_ORDER_TODAY')
      } else if (dateDiff <= 1) {
        return this.$t('LIST_ORDER_YESTERDAY')
      }
      return currentOrdersDate.format('LL')
    },
    isNewDay (index) {
      let currentDate = moment(this.orders[index].sending_date)
        .tz('Europe/Paris')
        .format('DD/MM/YYYY')
      if (index > 0) {
        var lastOrderStartDate = moment(
          this.orders[index - 1].sending_date
        )
          .tz('Europe/Paris')
          .format('DD/MM/YYYY')
      }
      return index === 0 || currentDate !== lastOrderStartDate
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/element-variables.scss';

.container-wrapper {
  background-color: #f8f9f8;
}

.order-day-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.order-day {
  &:not(:first-child) .order-day-title {
    margin-top: 40px;
  }
}

.all-activity-loaded {
  color: #aaa;
  background: transparent;
}
</style>
