// Load vue relative library
import Vue from 'vue'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'

// Load extern library
import axios from 'axios'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import VueHolder from 'vue-holderjs'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Load intern component
import App from './App'
import router from './router'

import storeModule from './store'
import i18n from './languages/i18n'
import '../theme/index.css'
import './assets/styles/app.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCoffee,
  faCircle,
  faStopwatch,
  faUsers,
  faPauseCircle,
  faPlayCircle,
  faPlay,
  faPause,
  faListUl,
  faCheck,
  faSearch,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import infiniteScroll from 'vue-infinite-scroll'
import tzData from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min.js'
import vueMoment from 'vue-moment'

window.moment = tzData
var vueScrollTo = require('vue-scrollto')

Vue.use(vueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -200,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

library.add(faCoffee)
library.add(faCircle)
library.add(faStopwatch)
library.add(faUsers)
library.add(faPauseCircle)
library.add(faPlayCircle)
library.add(faPlay)
library.add(faPause)
library.add(faPause)
library.add(faListUl)
library.add(faSearch)
library.add(faCheck)
library.add(faTimesCircle)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(vueMoment)
Vue.use(VueAxios, axios)
Vue.use(ElementUI, { locale })
Vue.use(Vuex)
Vue.use(VueHolder)

Vue.use(infiniteScroll)

// Setup
Vue.config.productionTip = false

// Store
const store = new Vuex.Store({
  modules: storeModule
})

// Interceptor to redirect on standard error, and intercept and store
// the token returned by the back-end
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    store.dispatch('auth/clearSession')
    window.location.href = '/login'
  }
  return Promise.reject(error.response ? error.response : error)
})

// scroll to top and init page's title when route changes
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

/* eslint-disable no-console */
console.info(`Version:%c ${process.env.VERSION}`, 'color: #4A90E2;')
/* eslint-enable no-console */

if (process.env.SENTRY_ENV){
  const currentHost = window.location.hostname.split(".")
  let domain = null

  if (currentHost.length == 4 && currentHost[1] == 'watchly') {
    currentHost.splice(0, 1)
    domain = currentHost.join('.')
  } else if (currentHost.length == 3 && currentHost[0] == 'watchly') {
    domain = currentHost.join('.')
  }

  if (domain) {
    Sentry.init({
      Vue,
      dsn: 'https://a3368f75edfc4afbb90d87c8089ce199@sentry.numberly.net/418',
      environment: process.env.SENTRY_ENV,
      release: process.env.VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [domain],
        }),
      ],
      tracesSampleRate: 1.0,
    })
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
