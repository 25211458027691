import Vue from 'vue'
import Router from 'vue-router'

// pages
import { ViewOrdersList, ViewMonitoring } from '@/components/View'
import i18n from '@/languages/i18n'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [{
    name: 'orders-list',
    path: '/',
    component: ViewOrdersList,
    beforeEnter: guardRoute
  },
  {
    name: 'monitoring',
    path: '/monitoring',
    component: ViewMonitoring,
    beforeEnter: guardRoute
  },
  {
    path: '*',
    redirect: '/'
  }]
})

async function guardRoute (to, from, next) {
  // work-around to get to the Vuex store (as of Vue 2.0)
  const store = router.app.$options.store
  const user = store.getters.user
  if (user) { next() }
  else {
    await store.dispatch('auth/getProfile')
      .then(res => {
        if (res.status === 401 || res.status === 403) {
          const redirect = res.status === 401 ? '/login' : '/logout'
          store.dispatch('auth/clearSession')
          window.location.href = redirect
        } else {
          store.dispatch('clients/getClients').finally(() => {
            const language = res.data.lang || 'en'
            Vue.moment.locale(language)
            i18n.locale = language
            document.body.setAttribute('loaded', 1)
            next()
          })
        }
      })
  }
}

export default router
