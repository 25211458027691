<template>
  <div>
    <el-row :gutter="15">
      <el-col
        v-if="errors"
        :span="6"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="6"
      >
        <div :class="['line' , offlineBackground('error-header')]" />
        <el-card>
          <!-- card body -->
          <div
            v-if="errors > 0"
            class="content"
          >
            <div class="content-icon">
              <i :class="['material-icons', 'md-34', offlineFontColor('error')]">
                error_outline
              </i>
              <span class="content-number">{{ errors }}</span>
              <span class="content-text">
                {{ $tc('MONITORING.HEADER_ERROR', errors) }}
              </span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col
        :span="6"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="6"
      >
        <div :class="['line', offlineBackground('in-progress-header')]" />
        <el-card>
          <!-- card body -->
          <div
            class="content"
          >
            <div class="content-icon">
              <i :class="['material-icons', 'md-34', offlineFontColor('in-progress')]">
                refresh
              </i>
              <span class="content-number">{{ inProgress }}</span>
              <span class="content-text">
                {{ $t('MONITORING.HEADER_PROGRESS') }}
              </span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col
        :span="6"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="6"
      >
        <div :class="['line', offlineBackground('scheduled-header')]" />
        <el-card>
          <!-- card body -->
          <div class="content">
            <div class="content-icon">
              <i :class="['material-icons', 'md-34',offlineFontColor('scheduled')]">
                event_note
              </i>
              <span class="content-number scheduled-value">
                {{ scheduled }}
              </span>
              <span class="content-text">
                {{ $tc('MONITORING.HEADER_SCHEDULED', scheduled) }}
                <span class="content-text-hours">
                  {{ $t('MONITORING.24_HOURS') }}
                </span>
              </span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col
        :span="6"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="6"
      >
        <div :class="['line', offlineBackground('success-header')]" />
        <el-card>
          <!-- card body -->
          <div class="content">
            <div class="content-icon">
              <i :class="['material-icons', 'md-34', offlineFontColor('success')]">
                send
              </i>
              <span class="content-number">{{ sent }}</span>
              <span class="content-text">
                {{ $tc('MONITORING.HEADER_SENT', sent) }}
              </span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Number,
      default: 0
    },
    inProgress: {
      type: Number,
      default: 0
    },
    scheduled: {
      type: Number,
      default: 0
    },
    sent: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isOnline () {
      return this.$store.getters['order/isOnline']
    }
  },
  methods: {
    offlineBackground (style) {
      if (!this.isOnline) return 'offline-background'
      else return style
    },
    offlineFontColor (style) {
      if (!this.isOnline) return 'offline-font'
      else return style
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.offline {
  &-font {
    color: $--color-monitoring-grey;
  }
  &-background {
    background-color: $--color-monitoring-grey;
  }
}
.el-card {
  height: 73px;
  width: 100%;
  box-shadow: none;
  border: 0.5px solid $--color-monitoring-grey;
  border-radius: 3px;
}
::v-deep .el-card__body {
  padding: 10px;
}
.el-col {
  position: relative;
  margin-bottom: 10px;
}
.line {
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 5px;
}
.content {
  position: relative;
  text-align: center;
  div {
    & > * {
      vertical-align: middle;
    }
  }
  &-icon {
    display: inline-block;
    text-align: center;
  }
  &-number {
    font-size: 40px;
  }
  &-text {
    font-size: 24px;
    &-hours {
      font-size: 16px;
    }
  }
}
.md-34 {
  font-size: 34px;
}
.success {
  color: $--color-success;
  &-header {
    background-color: $--color-success;
  }
}
.scheduled {
  &-value {
    margin-left: 50px;
  }
  color: $--color-info;
  &-header {
    background-color: $--color-info;
  }
}
.error {
  color: $--color-danger;
  &-header {
    background-color: $--color-danger;
  }
}
.in-progress {
  color: $--color-primary;
  &-header {
    background-color: $--color-primary;
  }
}
.material-icons {
  position: absolute;
  left: 15px;
  top: 12px;
}
</style>
