import Vue from 'vue'
import config from '@/config'

/* eslint camelcase: ["error", {properties: "never"}] */

const CLEAR_SESSION = 'CLEAR_SESSION'
const SET_USER = 'SET_USER'

const moduleAuth = {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    [SET_USER] (state, user) {
      Vue.set(state, 'user', user)
    },
    [CLEAR_SESSION] (state) {
      Vue.set(state, 'user', null)
    }
  },
  actions: {
    getProfile ({ commit }) {
      const url = `${config.API_URL}/sessions`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status >= 300) {
              reject(response)
            }
            commit(SET_USER, response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    clearSession ({ commit }) {
      commit(CLEAR_SESSION)
    },
    logout ({ commit }) {
      commit(CLEAR_SESSION)
      window.location = '/logout'
    }
  },
  getters: {
    user: state => state.user,
    userGroups: state => state.user.groups,
    userClients: state => state.user.clients,
    getGroup: (state) => (id) => {
      return state.user.groups.find(group => group.id === id)
    },
    getClient: (state) => (id) => {
      return state.user.clients.find(client => client.id === id)
    },
    isMultiClients: state => {
      return state.user.clients.length > 1 ||
        (window.location.hostname.split('.')).length === 3
    },
    hasPermissionInGroup: (state) => (groupId, perm) => {
      if (!state.user) {
        return false
      }
      return state.user.groups[groupId] &&
        state.user.groups[groupId].indexOf(perm) !== -1
    },
    hasPermission: (state, getters) => (perm) => {
      if (!state.user) {
        return false
      }
      let permSplitted = perm.split(':')
      if (permSplitted.length < 3) {
        return false
      }
      switch (permSplitted[1]) {
        case 'user':
          return state.user.permissions.indexOf(perm) !== -1
        case 'role': {
          for (var groupId in state.user.groups) {
            const res = getters.hasPermissionInGroup(groupId, perm)
            if (res) {
              return true
            }
          }
          return false
        }
        default:
          return false
      }
    }
  }
}

export default moduleAuth
