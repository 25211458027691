<template>
  <div>
    <div
      class="order-container"
      @click.self="order-container"
      @click="$emit('open-order-card', order.id)"
    >
      <el-card
        shadow="hover"
        class="order-card"
      >
        <el-row
          style="text-align:center;"
          type="flex"
          :gutter="20"
          justify="center"
          align="middle"
        >
          <el-col
            :span="12"
            class="flex"
          >
            <div
              class="container-logo"
            >
              <el-tooltip
                :content="avatarInfo.name"
                placement="top"
                effect="dark"
              >
                <span>
                  <avatar-holder
                    :avatar-url="avatarInfo.logo"
                    :display-name="avatarInfo.name"
                    :font="14"
                  />
                </span>
              </el-tooltip>
            </div>
            <div class="text-left margin-left-40">
              <span
                class="test-ab-icon"
              >
                <img
                  v-if="order.is_generalization"
                  :src="iconGene"
                  alt="icon generalization"
                >
                <img
                  v-else-if="order.is_ab_test"
                  :src="iconTest"
                  alt="icon AB-test"
                >
              </span>
              <span class="subtitle margin-top-20 margin-right-5 break-word">
                <span>
                  {{ order.crm_ids[0] }}
                </span>
                <span v-if="cleanCrmIds.length >= 2">
                  (+{{ cleanCrmIds.length - 1 }})
                </span>
              </span>
              <el-tooltip
                :content="order.campaign_name"
                effect="dark"
                placement="top"
              >
                <span class="title order-card__name">
                  {{ order.campaign_name }}
                </span>
              </el-tooltip>
            </div>
          </el-col>

          <el-col
            :span="8"
            class="flex"
          >
            <div class="text-left text-center flex">
              <el-tooltip
                class="item"
                effect="dark"
                :content="$i18n.t('ORDER_TOOLTIP_START_DATE')"
                placement="top"
              >
                <div class="subtitle text-center">
                  {{ startDate }}
                </div>
              </el-tooltip>
              <div
                class="subtitle text-center arrow-icon"
              >
                <i
                  class="material-icons"
                  :class="statusClass"
                >arrow_right_alt</i>
              </div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="endDateTooltipContent"
                :disabled="isPaused || isError"
                placement="top"
              >
                <div class="subtitle text-center">
                  {{ endDate }}
                </div>
              </el-tooltip>
            </div>
          </el-col>

          <el-col
            :span="8"
            class="flex"
          >
            <div
              class="subtitle status"
              style="padding-left:30px"
            >
              <i
                v-if="isInterrupted"
                class="material-icons status-icon"
                style="color: #4a90e2;"
              >
                hourglass_empty
              </i>
              <el-tag
                :type="statusClass"
                size="small"
                effect="dark"
              >
                {{ $t(status) }}
              </el-tag>
            </div>

            <div
              v-if="!resendInProgress"
              class="margin-right-40"
            >
              <el-tooltip
                v-if="!isError && order.channel_id !== refChannels.MOBILE && statusIcon"
                effect="dark"
                :content="$t(statusIconTooltip)"
                placement="bottom"
                :disabled="statusIconTooltip.length == 0"
              >
                <el-button
                  :class="['button-status', statusColorButtonClass]"
                  circle
                  @click.stop="$emit('open-campaign-pause-modal', order)"
                >
                  <i class="material-icons button-icon">
                    {{ statusIcon }}
                  </i>
                </el-button>
              </el-tooltip>
              <span
                v-else-if="isError && order.ignore_date"
                class="status-text"
              >
                <i>{{ $t("ORDER_STATUS_CANCELLED") }}</i>
              </span>
              <i
                v-else-if="statusIcon"
                class="button-icon-error material-icons button-icon"
              >
                {{ statusIcon }}
              </i>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import iconGene from '@/assets/img/icon_gene.svg'
import iconTest from '@/assets/img/icon_test.svg'
import { statusOrder } from '@/mixins/order'
import AvatarHolder from '@/components/UI/AvatarHolder.vue'
import { uniq } from 'lodash-es'

export default {
  name: 'OrderCard',
  components: { AvatarHolder },
  mixins: [statusOrder],
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isConfirming: false,
      orderDetails: {},
      isConfirmationModalOpened: false,
      refreshInterval: null,
      refChannels: config.CHANNELS
    }
  },
  computed: {
    iconGene () {
      return iconGene
    },
    iconTest () {
      return iconTest
    },
    user () {
      return this.$store.getters['auth/user']
    },
    isOnline () {
      return this.$store.getters['order/isOnline']
    },
    isMultiClients () {
      return this.$store.getters['auth/isMultiClients']
    },
    client () {
      return this.$store.getters['auth/getClient'](this.order.client_id)
    },
    group () {
      return this.$store.getters['auth/getGroup'](this.order.group_id)
    },
    endDateTooltipContent () {
      return this.$i18n.t(`ORDER_TOOLTIP_END_DATE${this.order.sending_end_date ? '' : '_ESTIMATED'}`)
    },
    cleanCrmIds () {
      return uniq(this.order.crm_ids)
    },
    resendInProgress () {
      return this.order.error && this.order.error.resent_status === config.RESEND_STATUS.ONGOING
    },
    statusIcon () {
      if (this.isError) {
        return 'error_outline'
      }
      else if (this.isPaused && ([this.refChannels.EMAIL, this.refChannels.PUSH_NOTIF].indexOf(this.order.channel_id) > -1)) {
        return 'play_arrow'
      } else if (
        !this.isError && !this.isPaused && !this.isSent &&
        (
          (this.order.channel_id == this.refChannels.EMAIL && this.isStarted && !this.isProcessing)
          ||
          (this.order.channel_id == this.refChannels.PUSH_NOTIF && this.isStarted && !this.isProcessing)
        )
      ) {
        return 'pause'
      }
      return ''
    },
    statusIconTooltip () {
      if (this.statusIcon === 'play_arrow') {
        return 'BUTTON_RESUME_SENDING'
      } else if (this.statusIcon === 'pause') {
        return 'BUTTON_PAUSE_SENDING'
      }
      return ''
    },
    statusColorButtonClass () {
      if (this.statusIcon === 'play_arrow') {
        return 'el-button--warning'
      } else if (this.statusIcon === 'pause') {
        return 'el-button--primary'
      }
      return ''
    },
    startDate () {
      return moment(this.order.sending_date)
        .tz(this.user.timezone)
        .format('LLL')
    },
    endDate () {
      if (this.order.sending_end_date == null) {
        if (this.order.estimated_end_date === null || this.isError) {
          return 'N/A'
        }
        if (this.isPaused) {
          return this.$i18n.t('ORDER_STATUS_PAUSE')
        }
        return moment(this.order.estimated_end_date)
          .tz(this.user.timezone)
          .format('LLL')
      } else {
        return moment(this.order.sending_end_date)
          .tz(this.user.timezone)
          .format('LLL')
      }
    },
    avatarInfo () {
      let returned_info = {}
      if (this.group) {
        returned_info = {
          name: this.group.name,
          logo: this.group.logo_url || null
        }
      }
      if (this.isMultiClients && this.client) {
        returned_info.name = this.client.name
        returned_info.logo = this.client.logo_url || null
      }
      return returned_info
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.status-text {
  color: $--color-monitoring-grey;
}

.order-details-modal-body {
  text-align: center;
  width: 90%;
  margin: auto;
}

.flex {
  display: flex;
  align-items: center;
}

.arrow-icon {
  margin-right: 10px;
  margin-left: 10px;
  .material-icons {
    color: $--color-separator
  }
}

.button {
  &-status {
    font-size: 20px;
    padding: 9px;
  }
  &-icon {
    color: white;
    vertical-align: bottom;
    &-error {
      color: $--color-danger;
      font-size: 50px;
    }
  }
}

.status {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 337px;
  margin-right: 60px;
  padding-left: 30px;
  &-icon {
    vertical-align: bottom;
    font-size: 15px !important;
    margin-right: 5px;
  }
}

.subtitle, .title {
  font-size: 14px;
}

.order {
  &-container {
    min-height: 96px;
  }
}

.order-card {
  width: 100%;
  padding: 5px 0px;
  background-color: #ffffff;
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    vertical-align: top;
    line-height: 19px;
  }
}

@media only screen and (max-width: 1400px) {
  .order-card__name {
    max-width: 250px;
  }
}

@media only screen and (max-width: 1260px) {
  .order-card__name {
    max-width: 200px;
  }
}

.order-card .el-card__body {
  padding: 15px;
}

.order-card:hover {
  cursor: pointer;
}

.order-details-row {
  border-bottom: 1px solid #ebeceb;
  line-height: 50px;
}

.container-logo {
  display: flex;
  .logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.test-ab-icon {
  position: absolute;
  left: 95px;
}
</style>
